import React from 'react';
import cls from './table.module.scss';
import { useTranslation } from 'react-i18next';

const Table = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* КРИВОЙ РОГ */}
      <div className={cls.table_container}>
        <h1 className={cls.main_text}>{t('tariffs')}*</h1>
        <p className={cls.tariffs_name}>{t('tariff_1')}</p>
        <div className={cls.table}>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_top}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('header_1')}</div>
            <div className={cls.cell}>{t('header_2')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>{t('header_3')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('econom')}</div>
            <div className={cls.cell}>53 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>9 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('standart')}</div>
            <div className={cls.cell}>55 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>9.5 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_bottom}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('comfort')}</div>
            <div className={cls.cell}>65 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>12 {t('hrn')}</div>
          </div>
        </div>
        {/*         <p className={cls.tariffs_name}>{t('tariff_2')}</p>
        <div className={cls.table}>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_top}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('header_1')}</div>
            <div className={cls.cell}>{t('header_2')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>{t('header_3')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('econom')}</div>
            <div className={cls.cell}>50 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>11,5 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('standart')}</div>
            <div className={cls.cell}>55 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>12 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_bottom}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('comfort')}</div>
            <div className={cls.cell}>65 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>13,5 {t('hrn')}</div>
          </div>
        </div>
        <p className={cls.tariffs_name}>{t('tariff_3')}</p>
        <div className={cls.table}>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_top}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('header_1')}</div>
            <div className={cls.cell}>{t('header_2')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>{t('header_3')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('econom')}</div>
            <div className={cls.cell}>50 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>16 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('standart')}</div>
            <div className={cls.cell}>55 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>17 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_bottom}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('comfort')}</div>
            <div className={cls.cell}>65 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>18 {t('hrn')}</div>
          </div>
        </div> */}
        {/* КРИВОЙ РОГ */}

        {/* ХАРЬКОВ */}
        <p className={cls.tariffs_name}>{t('tariff_ha_1')}</p>
        <div className={cls.table}>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_top}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('header_1')}</div>
            <div className={cls.cell}>{t('header_2')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>{t('header_3')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('econom')}</div>
            <div className={cls.cell}>52 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>9 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('standart')}</div>
            <div className={cls.cell}>54 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>9.5 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_bottom}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('comfort')}</div>
            <div className={cls.cell}>69 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>12 {t('hrn')}</div>
          </div>
        </div>
        {/* ХАРЬКОВ */}

        {/* ДНІПРО */}
        <p className={cls.tariffs_name}>{t('tariff_dp_1')}</p>
        <div className={cls.table}>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_top}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('header_1')}</div>
            <div className={cls.cell}>{t('header_2')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>{t('header_3')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('econom')}</div>
            <div className={cls.cell}>62 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>11 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('standart')}</div>
            <div className={cls.cell}>65 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>12 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_bottom}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('comfort')}</div>
            <div className={cls.cell}>70 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>13 {t('hrn')}</div>
          </div>
        </div>
        {/* ДНІПРО */}

        {/* ЗАПОРІЖЖЯ */}
        <p className={cls.tariffs_name}>{t('tariff_zp_1')}</p>
        <div className={cls.table}>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_top}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('header_1')}</div>
            <div className={cls.cell}>{t('header_2')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>{t('header_3')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('econom')}</div>
            <div className={cls.cell}>53 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>9 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('standart')}</div>
            <div className={cls.cell}>57 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>9.5 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_bottom}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('comfort')}</div>
            <div className={cls.cell}>65 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>12 {t('hrn')}</div>
          </div>
        </div>
        {/* ЗАПОРІЖЖЯ */}

        {/* БІЛА ЦЕРКВА */}
        <p className={cls.tariffs_name}>{t('tariff_bc_1')}</p>
        <div className={cls.table}>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_top}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('header_1')}</div>
            <div className={cls.cell}>{t('header_2')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>{t('header_3')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('econom')}</div>
            <div className={cls.cell}>47 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>9 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('standart')}</div>
            <div className={cls.cell}>50 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>10 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_bottom}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('comfort')}</div>
            <div className={cls.cell}>58 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>11 {t('hrn')}</div>
          </div>
        </div>
        {/* БІЛА ЦЕРКВА */}
        {/* ЖИТОМИР */}
        <p className={cls.tariffs_name}>{t('tariff_jt_1')}</p>
        <div className={cls.table}>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_top}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('header_1')}</div>
            <div className={cls.cell}>{t('header_2')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>{t('header_3')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('econom')}</div>
            <div className={cls.cell}>55 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>10 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('standart')}</div>
            <div className={cls.cell}>59 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>11 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_bottom}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('comfort')}</div>
            <div className={cls.cell}>69 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>12 {t('hrn')}</div>
          </div>
        </div>
        {/* ЖИТОМИР */}
        {/* КРОПИВНИКЬКИЙ */}
        <p className={cls.tariffs_name}>{t('tariff_krop_1')}</p>
        <div className={cls.table}>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_top}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('header_1')}</div>
            <div className={cls.cell}>{t('header_2')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>{t('header_3')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('econom')}</div>
            <div className={cls.cell}>47 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>8 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('standart')}</div>
            <div className={cls.cell}>49 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>9 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_bottom}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('comfort')}</div>
            <div className={cls.cell}>51 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>10 {t('hrn')}</div>
          </div>
        </div>
        {/* КРОПИВНИКЬКИЙ */}
        {/* Кременчук */}
        <p className={cls.tariffs_name}>{t('tariff_krem_1')}</p>
        <div className={cls.table}>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_top}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('header_1')}</div>
            <div className={cls.cell}>{t('header_2')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>{t('header_3')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('econom')}</div>
            <div className={cls.cell}>48 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>9 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('standart')}</div>
            <div className={cls.cell}>50 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>10 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_bottom}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('comfort')}</div>
            <div className={cls.cell}>59 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>11 {t('hrn')}</div>
          </div>
        </div>
        {/* Кременчук */}
        {/* Кам’янське */}
        <p className={cls.tariffs_name}>{t('tariff_ka_1')}</p>
        <div className={cls.table}>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_top}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('header_1')}</div>
            <div className={cls.cell}>{t('header_2')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>{t('header_3')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('econom')}</div>
            <div className={cls.cell}>48 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>9 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('standart')}</div>
            <div className={cls.cell}>50 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>10 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_bottom}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('comfort')}</div>
            <div className={cls.cell}>59 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>11 {t('hrn')}</div>
          </div>
        </div>
        {/* Кам’янське */}
        {/* ЧЕРНІГІВ */}
        <p className={cls.tariffs_name}>{t('tariff_ch_1')}</p>
        <div className={cls.table}>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_top}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('header_1')}</div>
            <div className={cls.cell}>{t('header_2')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>{t('header_3')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('econom')}</div>
            <div className={cls.cell}>48 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>8 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('standart')}</div>
            <div className={cls.cell}>51 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>9 {t('hrn')}</div>
          </div>
          <div className={cls.row + ' ' + cls.border_none_sides + ' ' + cls.border_none_bottom}>
            <div className={cls.cell + ' ' + cls.border_none_left}>{t('comfort')}</div>
            <div className={cls.cell}>59 {t('hrn')}</div>
            <div className={cls.cell + ' ' + cls.border_none_right}>11 {t('hrn')}</div>
          </div>
        </div>
        <p className={cls.footnote}>*{t('footnote')}</p>
        {/* ЧЕРНІГІВ */}
      </div>
    </>
  );
};

export default Table;
