import React from 'react';
import cls from "./media.module.scss"
import { useTranslation } from 'react-i18next';
import MediaFirst from "../../../assets/media/MediaFirst.svg"
import MediaSecond from "../../../assets/media/MediaSecond.svg"
import MediaThird from "../../../assets/media/MediaThird.svg"
import MediaFourth from "../../../assets/media/MediaFourth.svg"
import AdvantagesFirst from "../../../assets/advantages/AdvantagesFirst.svg"
import AppStoreLabelFull from "../../../assets/AppStoreLabelFull.svg"
import PlayMarketLabelFull from "../../../assets/PlayMarketLabelFull.svg"

const Media = () => {
  const { t } = useTranslation()
  return (
    <div className={cls.media_container}>
      <div className={cls.first_block}>
        <p className={cls.main_text}>{t('main_text_36')}</p>
        <div className={cls.media_block}>
          <div className={cls.row_item}>
            <div className={cls.one_item}>
              <div className={cls.icon_item}>
                <img src={MediaFirst} alt='' />
              </div>
              <p className={cls.text_item}>{t('main_text_37')}</p>
            </div>
            <div className={cls.one_item + ' ' + cls.another_item}>
              <div className={cls.icon_item}>
                <img src={MediaThird} alt='' />
              </div>
              <p className={cls.text_item}>{t('main_text_39')}</p>
            </div>
            <div className={cls.one_item + ' ' + cls.other_item}>
              <div className={cls.icon_item}>
                <img src={MediaSecond} alt='' />
              </div>
              <p className={cls.text_item}>{t('main_text_38')}</p>
            </div>
          </div>
          <div className={cls.row_item}>
            <div className={cls.one_item + ' ' + cls.another_item}>
              <div className={cls.icon_item}>
                <img src={MediaSecond} alt='' />
              </div>
              <p className={cls.text_item}>{t('main_text_38')}</p>
            </div>
            <div className={cls.one_item + ' ' + cls.other_item}>
              <div className={cls.icon_item}>
                <img src={MediaThird} alt='' />
              </div>
              <p className={cls.text_item}>{t('main_text_39')}</p>
            </div>
            <div className={cls.one_item}>
              <div className={cls.icon_item}>
                <img src={MediaFourth} alt='' />
              </div>
              <p className={cls.text_item + ' ' + cls.last_item}>{t('main_text_40')}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={cls.second_block}>
        <div className={cls.second_icon}>
          <img src={AdvantagesFirst} alt='' />
        </div>
        <p className={cls.text_global} style={{ whiteSpace: 'pre-line' }}>{t('download_app_second')}</p>
        {/* dirver */}
{/*         <a href="#">
          <img src={AppStoreLabelFull} alt='' />
        </a> */}
        <a href="https://play.google.com/store/apps/details?id=com.lttaxi.driver" style={{ paddingTop: "24px" }}>
          <img src={PlayMarketLabelFull} alt='' />
        </a>
      </div>
    </div>
  );
}

export default Media;