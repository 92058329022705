import React from "react";
import { ReactComponent as AppStoreLabel } from "src/assets/AppStoreLabel.svg";
import { ReactComponent as PlayMarketLabel } from "src/assets/PlayMarketLabel.svg";
import bgSk from "src/assets/main-page/bg-sk.png";

import style from "./styles.module.scss";

const SkView = () => {
  return (
    <div className={style.main_block}>
      <img className={style.main_image} src={bgSk} alt="women and car" />
      <div className={style.sk_share_buttons}>
        {/* client */}
{/*         <a href="#">
          <AppStoreLabel className={style.unfull_link} />
        </a> */}
        <a href="https://play.google.com/store/apps/details?id=com.lttaxi.client">
          <PlayMarketLabel className={style.unfull_link} />
        </a>
      </div>
    </div>
  );
};

export default SkView;
