import React from 'react';
import cls from "./advantages.module.scss"
import { useTranslation } from 'react-i18next';
import AdvantagesFirst from "../../../assets/advantages/AdvantagesFirst.svg"
import AdvantagesSecond from "../../../assets/advantages/AdvantagesSecond.svg"
import AdvantagesThird from "../../../assets/advantages/AdvantagesThird.svg"
import AppStoreLabel from "../../../assets/AppStoreLabel.svg"
import PlayMarketLabel from "../../../assets/PlayMarketLabel.svg"
import AppStoreLabelFull from "../../../assets/AppStoreLabelFull.svg"
import PlayMarketLabelFull from "../../../assets/PlayMarketLabelFull.svg"

const Advantages = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className={cls.advantages_container}>
        <p className={cls.main_text}>{t('main_text_19')}</p>
        <div className={cls.advantages_block}>
          <div className={cls.main_item}>
            <div className={cls.advantages_item}>
              <img src={AdvantagesFirst} alt='' />
            </div>
            <p className={cls.text_align_first}>{t('main_text_20')}</p>
          </div>
          <div className={cls.main_item}>
            <div className={cls.advantages_item}>
              <img src={AdvantagesSecond} alt='' />
            </div>
            <p className={cls.text_align_second}>{t('main_text_21')}</p>
          </div>
          <div className={cls.main_item}>
            <div className={cls.advantages_item}>
              <img src={AdvantagesThird} alt='' />
            </div>
            <p className={cls.text_align_third}>{t('main_text_22')}</p>
          </div>
        </div>
      </div>
      <div className={cls.download_icons}>
        {/* client */}
{/*         <a className={cls.unfull} href='#'>
          <img src={AppStoreLabel} alt='' />
        </a> */}
        <a className={cls.unfull} href='https://play.google.com/store/apps/details?id=com.lttaxi.client'>
          <img src={PlayMarketLabel} alt='' />
        </a>
        {/* client */}
{/*         <a className={cls.full} href='#'>
          <img src={AppStoreLabelFull} alt='' />
        </a> */}
        <a className={cls.full} href='https://play.google.com/store/apps/details?id=com.lttaxi.client'>
          <img src={PlayMarketLabelFull} alt='' />
        </a>
      </div>
    </>
  );
}

export default Advantages;