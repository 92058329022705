import React from 'react';
import cls from './loader.module.scss';
import LoaderEllipse from "../../../assets/loader/LoaderEllipse.svg"
import LoaderLine from "../../../assets/loader/LoaderLine.svg"

const Loader = () => {

  return (
    <div className={cls.loader_container}>
      <div className={cls.loader_block}>
        <div className={cls.loader_ellipse}>
          <img src={LoaderEllipse} alt='' />
          <img className={cls.loader_line} src={LoaderLine} alt='' />
        </div>
      </div>
    </div>
  );
};

export default Loader;
