import React, { useState, useRef, useEffect } from "react";
import { Link } from "../../../helper/Link";
import { changeLanguage, t, language } from "i18next";
import * as i18lib from "i18next";
import cls from "./layout.module.scss";
import "./screen.scss";
import { ReactComponent as CarmaLogo } from "../../../assets/CarmaLogo.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/PhoneIcon.svg";
import { ReactComponent as AppStoreIcon } from "../../../assets/AppStoreIcon.svg";
import { ReactComponent as LineIcon } from "../../../assets/LineIcon.svg";
import { ReactComponent as PlayMarketIcon } from "../../../assets/PlayMarketIcon.svg";
import { ReactComponent as ArrowDown } from "../../../assets/ArrowDown.svg";
import { ReactComponent as MenuIcon } from "../../../assets/MenuIcon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/CloseIcon.svg";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import CookieBar from "src/components/cookie-bar";
import Logo from '../../../assets/logo.png';

const phoneNumber = "699";

const Layout = () => {
  const location = useLocation();
  const [lang, setLang] = useState(Cookies.get("language_name") || "EN");
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const selectRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const userCountry = Cookies.get("country");
  const showPhoneNumber = userCountry === "UA";

  useEffect(() => {
    const langCookie = Cookies.get("language_name");
    if (langCookie) {
      setLang(langCookie);
      changeLanguage(langCookie);
    }
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  };

  const navigation = [
    { name: t("main"), path: "/" },
    { name: t("for_passengers"), path: "/passenger" },
    { name: t("for_drivers"), path: "/driver" },
    { name: t('tariffs'), path: '/tariffs' },
    { name: t("about_company"), path: "/about" },
  ];

  const handleLanguageChange = (e) => {
    const newLang = e.target.value;
    changeLanguage(newLang);
    Cookies.set("language_name", newLang, { expires: 30 });
    setLang(newLang);
  };

  const toggleSelect = () => {
    setIsSelectOpen(!isSelectOpen);
  };

  const closeSelect = () => {
    setIsSelectOpen(false);
  };

  const currentPath = location.pathname;

  return (
    <div className={cls.layout_container}>
      <CookieBar />
      <div className={cls.layout_block}>
        {/*       <Link to="/" className={cls.logo}>
          <CarmaLogo />
        </Link> */}
        {/*         <a href={`tel:${phoneNumber}`} className={cls.logo}>
          <CarmaLogo />
        </a> */}
        {showPhoneNumber && (
          <div className={cls.btn_logo_container}>
            <div className={cls.yellow_block_first}>
              <div className={cls.phone_icon}>
                <PhoneIcon style={{ paddingRight: "8px" }} />
              </div>
              <a href={`tel:${phoneNumber}`}>699</a>
            </div>

            <div className={`${cls.yellow_block_first} ${cls.logo}`}>
              <div className={cls.phone_icon}>
                <img src={Logo} alt='' />
              </div>
              <a href={`tel:${phoneNumber}`}>TAXI</a>
            </div>
          </div>
        )}
        <div className={cls.links_block}>
          {navigation.map((navigation) => (
            <div
              className={`${cls.link} ${currentPath === navigation.path ? cls.active_link : ""
                }`}
              key={navigation.name}
            >
              <Link to={navigation.path}>{navigation.name}</Link>
            </div>
          ))}
        </div>
        <div className={cls.options}>
          <div className={cls.select_wrapper}>
            <div
              className={`${cls.custom_select} ${isSelectOpen ? cls.opened : ""
                }`}
              onClick={toggleSelect}
              onBlur={closeSelect}
              tabIndex={0}
              ref={selectRef}
            >
              <span>{t(lang)}</span>
              <div className={cls.arrow_down_icon}>
                <ArrowDown />
              </div>
              <div className={cls.options_list}>
                <div
                  className={cls.option}
                  onClick={() =>
                    handleLanguageChange({ target: { value: "EN" } })
                  }
                >
                  {t("en")}
                </div>
                {showPhoneNumber ? (
                  <div
                    className={cls.option}
                    onClick={() =>
                      handleLanguageChange({ target: { value: "UA" } })
                    }
                  >
                    {t("ua")}
                  </div>
                ) : (
                  <div
                    className={cls.option}
                    onClick={() =>
                      handleLanguageChange({ target: { value: "SK" } })
                    }
                  >
                    {t("sk")}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={cls.yellow_blocks}>
            {/*             {showPhoneNumber && (
              <div className={cls.yellow_block_first}>
                <div className={cls.phone_icon}>
                  <PhoneIcon style={{ paddingRight: "8px" }} />
                </div>
                <a href={`tel:${phoneNumber}`}>1699</a>
              </div>
            )} */}
            <div className={cls.yellow_block_second}>
              {/* client */}
              <a
                href="#"
                className={cls.app_store_icon}
              >
                <AppStoreIcon />
              </a>
              <div className={cls.line_icon}>
                <LineIcon />
              </div>
              <a
                href="https://play.google.com/store/apps/details?id=com.lttaxi.client"
                className={cls.play_market_icon}
              >
                <PlayMarketIcon />
              </a>
            </div>
          </div>
        </div>
        <div className={cls.menu_item} onClick={toggleMenu}>
          {isOpen ? <CloseIcon /> : <MenuIcon />}
        </div>
        <div
          className={
            isOpen
              ? `${cls.mobile_menu} ${cls.mobile_menu_open}`
              : cls.mobile_menu
          }
        >
          <div className={cls.select_wrapper_mobile}>
            <div
              className={`${cls.custom_select_mobile} ${isSelectOpen ? cls.opened_mobile : ""
                }`}
              onClick={toggleSelect}
              onBlur={closeSelect}
              tabIndex={0}
              ref={selectRef}
            >
              <span>{t(lang)}</span>
              <div className={cls.arrow_down_icon_mobile}>
                <ArrowDown />
              </div>
              <div className={cls.options_list_mobile}>
                <div
                  className={cls.option_mobile}
                  onClick={() =>
                    handleLanguageChange({ target: { value: "EN" } })
                  }
                >
                  {t("en")}
                </div>
                <div
                  className={cls.option_mobile}
                  onClick={() =>
                    handleLanguageChange({ target: { value: "SK" } })
                  }
                >
                  {t("sk")}
                </div>
                {showPhoneNumber && (
                  <div
                    className={cls.option_mobile}
                    onClick={() =>
                      handleLanguageChange({ target: { value: "UA" } })
                    }
                  >
                    {t("ua")}
                  </div>
                )}
              </div>
            </div>
          </div>
          {navigation.map((navigation) => (
            <div
              className={`${cls.mobile_link} ${currentPath === navigation.path ? cls.mobile_active_link : ""
                }`}
              key={navigation.name}
            >
              <Link to={navigation.path} onClick={toggleMenu}>
                {navigation.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Layout;
